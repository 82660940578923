<template>
    <module-frame :module-id="moduleId">
        <div class="fk-floatImgContainer J_floatImgContainer">
            <a
                hidefocus="true"
                :class="AClassList"
                :rel="notFollow"
                :onclick="jumpOnclickStr"
                @click.stop="picLinkClick($event)"
            >
                <div
                    class="floatImgWrap J_floatImgWrap"
                    :style="floatImgWrapStyle"
                    :data-picid="imgId"
                    @click="openPictureSlidesV4"
                    @dblclick="doubleEdit"
                >
                    <img
                        v-if="!isFontIcon"
                        v-ban-copy
                        :class="imgClass"
                        :src="imgSrc"
                        :src-original="picThumbPath"
                        :usemap="picMap"
                        :style="imgStyle"
                        :alt="alt"
                        @click="uploadPic"
                    />
                    <div v-else :class="fontClassList" :style="fontIconStyle"></div>
                    <map v-if="hotAreaList.length > 0 && isHotArea" :id="hotAreaName" :name="hotAreaName">
                        <area
                            v-for="(item, index) in hotAreaList"
                            :key="'hotArea_' + index"
                            shape="rect"
                            hidefocus="true"
                            onfocus="this.blur()"
                            :coords="getAreaPosition(item)"
                            :href="getAreaLink(item)"
                            :title="item.tip"
                            :onclick="hotAreaOnclickStr(item)"
                            @click.stop="areaClick($event, item)"
                        />
                    </map>
                </div>
            </a>

            <image-viewer
                v-if="showMobiViewer"
                :z-index="zIndex"
                :initial-index="initialIndex"
                :on-close="onClose"
                :url-list="urlList"
                :is-mobi="true"
                :content-terminal="'mobi'"
                :manage-mode="manageMode"
                :log-event-config="logEventConfig"
                :document-event-el="documentEventEl"
                append-to-container-id="g_body"
                :append-to-body="false"
                :disable-long-press="banCopyTextImg"
            />
        </div>
    </module-frame>
</template>
<script>
import { createUrlArgsOnclickStr } from '../../comm';
import { slidesShowDataMixins, slidesshowMethodsMixins } from '../../moduleMixins';
import { isBrowserEnv } from '@jz/utils';
import ModuleFrame from '@/modules/frame/index.vue';

import { logDog } from '@/shared/log';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { MODULE_STYLE } from '@/def/module';
import { fileUpload2 } from '@/shared/fileUpload';
import { floatImgResize } from '../util';
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';
import { changeHref } from '@/features/router/universal';
const manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'FloatImg',
    components: {
        ModuleFrame,
    },
    mixins: [slidesShowDataMixins, slidesshowMethodsMixins],
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            imgWidth: 0,
            imgHeight: 0,
            packWidth: 0,
            timer: null,
            hotAreaRealWidth: 0, // 热区真正的宽度
            hotAreaRealHeight: 0, // 热区真正的高度
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'mobi-图片',
                    jz_version: isBrowserEnv() ? Fai.top.jzVersion : 0,
                },
            },
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['openThemeV3']),
        ...mapGetters('app', ['hashRemoved']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions || {};
        },
        moduleAttr() {
            return this.$store.state.manage.moduleAttr[`module${this.moduleId}`] || {};
        },
        urlList() {
            let images = [
                {
                    src: this.picThumbPath,
                    description: this.moduleContent.alt,
                },
            ];
            return images;
        },
        AClassList() {
            return this.isInit ? ['J_floatImg_jump'] : ['J_floatImg_jump', 'floatImgALink'];
        },
        jumpOnclickStr() {
            if (this.isHotArea) {
                return null;
            }
            return createUrlArgsOnclickStr(this.linkInfo.reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        AHref() {
            if (!this.isHotArea) {
                if (this.imgId == '') {
                    return 'javascript:void(0);';
                } else {
                    if ((this.linkInfo.jt == '103' && manageMode) || (this.linkInfo.jt == 204 && manageMode)) {
                        return "javascript:top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true)";
                    }
                    return this.linkInfo.jUrl != '' ? this.linkInfo.jUrl : 'javascript:void(0);';
                }
            } else {
                return 'javascript:void(0);';
            }
        },
        notFollow() {
            return this.moduleContent.jnf ? 'nofollow' : '';
        },
        imgClass() {
            return !this.isInit
                ? ['fade-in-for-lazyload', 'J_img_lazyload', 'float_img', 'J_float_img', 'J_photo']
                : [
                      'fade-in-for-lazyload',
                      'J_img_lazyload',
                      'float_img',
                      'J_float_img',
                      'J_photo',
                      'float_img_default',
                  ];
        },
        fontClassList() {
            return ['float_img', 'J_float_img', 'f-fonticon', 'J_fonticon', this.fontIcon.fc];
        },
        htmlFontSize() {
            return this.otherInfo.htmlFontSize || 23.1875;
        },
        picThumbPath() {
            return this.otherInfo.picThumbPath;
        },
        picMap() {
            return `#hotAreaMap${this.module.id}`;
        },
        isFontIcon() {
            return this.moduleContent.ifi;
        },
        moduleContent() {
            return this.module.content;
        },
        alt() {
            return this.moduleContent.alt;
        },
        otherInfo() {
            return this.module.otherInfo;
        },
        isInit() {
            return this.picThumbPath == this.defaultImgPath;
        },
        manageMode() {
            return manageMode;
        },
        modulePattern() {
            return this.module.pattern;
        },
        modulePatternV3() {
            return this.module.patternV3;
        },
        imgStyle() {
            let style = {};
            let type = this.shapeEffect.imgShapeType;
            let maxSize,
                minSize,
                isWidthLarge = false;

            if (this.modulePattern.w > this.modulePattern.h) {
                minSize = this.modulePattern.h;
                maxSize = this.modulePattern.w;
                isWidthLarge = true;
            } else {
                minSize = this.modulePattern.w;
                maxSize = this.modulePattern.h;
            }

            if (!this.isInit) {
                style['width'] = this.modulePattern.w / this.htmlFontSize + 'rem'; // 这里比较特殊，pattern里存的px值(640下)
                style['height'] = this.modulePattern.h / this.htmlFontSize + 'rem';
            }

            if (type == 1 || type == 3) {
                style['margin'] = 0;
                if (isWidthLarge) {
                    style['margin-left'] = -(maxSize - minSize) / (this.htmlFontSize * 2) + 'rem';
                } else {
                    style['margin-top'] = -(maxSize - minSize) / (this.htmlFontSize * 2) + 'rem';
                }
            }
            return style;
        },
        floatImgWrapStyle() {
            let style = {};
            let type = this.shapeEffect.imgShapeType;
            let radius = this.shapeEffect.borderRadius;
            let maxSize,
                minSize,
                isWidthLarge = false;
            if (this.modulePattern.w > this.modulePattern.h) {
                minSize = this.modulePattern.h;
                maxSize = this.modulePattern.w;
                isWidthLarge = true;
            } else {
                minSize = this.modulePattern.w;
                maxSize = this.modulePattern.h;
            }

            if (type == 1 || type == 3) {
                if (type == 3) style['border-radius'] = '50%';
                style['width'] = minSize / this.htmlFontSize + 'rem';
                style['height'] = minSize / this.htmlFontSize + 'rem';
                style['margin'] = '0';
                if (isWidthLarge) {
                    style['margin-left'] = (maxSize - minSize) / (this.htmlFontSize * 2) + 'rem';
                } else {
                    style['margin-top'] = (maxSize - minSize) / (this.htmlFontSize * 2) + 'rem';
                }
            } else if (type == 2) {
                if (!this.isInit) {
                    style['border-radius'] = radius / this.htmlFontSize + 'rem';
                }
            }
            if (!this.isInit) {
                style['opacity'] = this.transparent / 100;
                style = { ...style, ...this.shadowStyle };
            }
            return style;
        },
        fontIcon() {
            return this.moduleContent.fIcon;
        },
        imgId() {
            if (this.isFontIcon) {
                return this.fontIcon.fi;
            } else if (this.moduleContent.pi) {
                return this.moduleContent.pi;
            } else if (this.moduleContent.ipc) {
                return this.moduleContent.ipc;
            } else {
                return '';
            }
        },
        imgPicSize() {
            return this.otherInfo.picRect;
        },
        linkInfo() {
            return this.otherInfo.linkInfo;
        },
        defaultImgPath() {
            return this.otherInfo.defaultImg;
        },
        hotAreaName() {
            return 'hotAreaMap' + this.moduleId;
        },
        hotAreaList() {
            let reverseList = [];
            reverseList = this.moduleContent.hotAreaList.map((item) => {
                return item;
            });
            return reverseList.reverse();
        },
        hotImgHeight() {
            return this.moduleContent.imgHeight; // 编辑热区弹框中图片的高度
        },
        hotAreaWidthScale() {
            return this.hotAreaRealWidth / 440; // 440：编辑热区弹框中图片的宽度
        },
        hotAreaHeightScale() {
            return this.hotAreaRealHeight / this.hotImgHeight;
        },
        isHotArea() {
            return this.moduleContent.jMode == 1;
        },
        isJumpWxApp() {
            return this.moduleContent.jMode == 2;
        },
        shapeEffect() {
            return this.moduleContent.shapeEffect;
        },
        transparent() {
            if (this.openThemeV3) {
                return 100 - this.modulePatternV3.o;
            } else {
                return this.modulePattern.tran;
            }
        },
        shadowStyle() {
            let style = {};
            if (this.shadowContent.type == 1) {
                style['box-shadow'] =
                    this.shadowContent.xa +
                    'rem ' +
                    this.shadowContent.ya +
                    'rem ' +
                    this.shadowContent.radius +
                    'rem ' +
                    this.shadowContent.color;
            }
            return style;
        },

        shadowContent() {
            return this.moduleContent.fis || {};
        },

        fontIconStyle() {
            let style = {};
            style['width'] = this.modulePattern.w / this.htmlFontSize + 'rem'; // 这里比较特殊，pattern里存的px值(640下)
            style['height'] = this.modulePattern.h / this.htmlFontSize + 'rem';
            style['font-size'] =
                this.modulePattern.w > this.modulePattern.h
                    ? this.modulePattern.h / this.htmlFontSize + 'rem'
                    : this.modulePattern.w / this.htmlFontSize + 'rem';
            style['line-height'] = this.modulePattern.h / this.htmlFontSize + 'rem';
            style['color'] = this.fontIcon.fco;

            return style;
        },
        isUsePathSrc() {
            return this.moduleContent.ipc !== '';
        },
        imgSrc() {
            return this.module.content.pi == ''
                ? this.module.otherInfo.defaultImg
                : this.options.isFirstScreen
                ? this.picThumbPath
                : this.module.otherInfo.loadingPath;
        },
    },
    watch: {
        // 'module.content': {
        //     handler(val) {
        //         if (manageMode) {
        //             this.moduleAttr.contentChange = true;
        //             this.moduleAttr.content = JSON.parse(JSON.stringify(val));
        //             this.$store.commit('updateModuleAttr', {
        //                 id: this.module.id,
        //                 attr: this.moduleAttr,
        //             });
        //             Vue.prototype.$designer.styleChanged();
        //         }
        //     },
        //     deep: true,
        // },
        picThumbPath: {
            handler(val, oldVal) {
                this.$nextTick(() => {
                    let $img = jm('#module' + this.module.id + ' img');
                    if ($img.length) {
                        $img[0].src = this.picThumbPath;
                    }
                    if (manageMode) {
                        if (val == this.defaultImgPath) {
                            this.resetPicStyle(); // 删除图片后的操作
                        } else {
                            // 修改图片后的操作
                            let isInit = oldVal == this.defaultImgPath;
                            this.setPicInfo(isInit);
                            this.refreshModule(isInit);
                        }
                    }
                });
            },
        },
        'shapeEffect.imgShapeType': {
            handler() {
                if (manageMode) {
                    this.setModuleDataToDom();
                }
            },
        },
        modulePattern: {
            handler(val, oldVal) {
                if (manageMode) {
                    let $module = $('#module' + this.moduleId);
                    $module.attr('_autoHeight', 0).attr('_height', val.h);
                    $module.attr('_autoWidth', 0).attr('_width', val.w);

                    this.moduleAttr.pattern.changed = true;
                    // 更新模块高度
                    let allWidthZero = val.w == 0 && oldVal.w == 0;
                    let alllHeightZero = val.h == 0 && oldVal.h == 0;
                    if (!(allWidthZero && alllHeightZero)) {
                        $module.css('width', val.w / this.htmlFontSize + 'rem');
                        $module.css('height', val.h / this.htmlFontSize + 'rem');
                    }

                    floatImgResize($module); // 更新图片 拖拽
                    this.updateHotAreaSize();
                    Vue.prototype.$designer.styleChanged();
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (!this.options.isFirstScreen) {
            bindImgLazyLoad(`module${this.module.id}`);
        }
        this.$nextTick(() => {
            this.setModuleDataToDom();
            this.removeContainerOpacity();
            this.updateHotAreaSize();
            this.initJumpWxApp();
        });
    },
    methods: {
        onClose() {
            this.showMobiViewer = false;
        },
        initJumpWxApp() {
            if (this.moduleContent.jMode !== 2) {
                return;
            }
            Mobi.initJumpWxApp(this.moduleId, this.moduleContent.jumpWxAppData);
        },
        hotAreaOnclickStr(item) {
            const { reqArgs = '' } = item;
            // --bug=1079486 --user=陈奖 【工单反馈】苹果手机ios14/ios15系统，点击图片热点区域，没有跳转。 https://www.tapd.cn/42954297/s/1422917
            return createUrlArgsOnclickStr(reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        uploadPic() {
            if (manageMode && this.moduleContent.pi == '' && !this.isUsePathSrc) {
                //文件上传2.0 图片上传
                var title = this.moduleContent.pi == '' && !this.isUsePathSrc ? '添加图片' : '修改图片';
                var fileUpload2_settings = {
                    title,
                    type: ['jpg', 'jpeg', 'bmp', 'png', 'gif'],
                    entry: 'floatimg',
                    group: 'image',
                    from: 'pic',
                };
                fileUpload2(null, fileUpload2_settings, this.uploadPicCallback);
            }
        },

        // 上传图片后的回调
        uploadPicCallback(back) {
            let self = this;
            if (back) {
                let resultBack = $.parseJSON(back),
                    fileData = resultBack.data[0] || {},
                    fileId = fileData.fileId;

                var undoArgs = {
                    width: this.imgPicSize.width,
                    height: this.imgPicSize.height,
                    path: this.otherInfo.picThumbPath,
                    pId: this.imgId,
                    color: this.fontIcon.color,
                };

                var doArgs = {
                    width: fileData.fileWidth,
                    height: fileData.fileHeight,
                    path: fileData.filePath,
                    pId: fileData.fileId,
                    color: fileData.color,
                };

                this.imgPicSize.width = fileData.fileWidth; // 记录图片原始值（宽度、高度）
                this.imgPicSize.height = fileData.fileHeight;
                this.otherInfo.picThumbPath = fileData.filePath;
                this.setPicId(fileId, fileData.filePath, fileData.color);

                this.$undo.undoRun(
                    function (options) {
                        self.imgPicSize.width = options.width;
                        self.imgPicSize.height = options.height;
                        self.otherInfo.picThumbPath = options.path;
                        self.setPicId(options.pId, options.path, options.color);
                    },
                    {
                        type: 'floatImgupload',
                        detail: 'floatImgupload',
                        doArgs: [doArgs],
                        undoArgs: [undoArgs],
                    }
                );
            }
        },

        // 处理最终的宽、高
        setPicInfo(isInit) {
            // 这里更新图片模块高度
            let $module = jm('#module' + this.moduleId),
                packId = +$module.attr('_inpack'),
                popupZoneId = +$module.attr('_inpopupzone'),
                flaotZoneId = +$module.attr('_infloatzone'),
                imgWidth = this.imgPicSize.width,
                imgHeight = this.imgPicSize.height,
                moduleWidth = $module[0].offsetWidth,
                moduleHeight = $module[0].offsetHeight,
                packWidth = 328,
                packHeight = 200;

            let $packDom, imgSize, size;

            if (packId) {
                $packDom = jm('#fk-packContent' + packId);
                packWidth = $packDom[0].offsetWidth;
                packHeight = $packDom[0].offsetHeight;
            } else if (popupZoneId) {
                $packDom = jm('#module' + popupZoneId).find('.J_formPopupZoneContent');
                packWidth = $packDom[0].offsetWidth;
                packHeight = $packDom[0].offsetHeight;
            } else if (flaotZoneId) {
                $packDom = jm('#module' + flaotZoneId).find('.float_zone_wrap');
                packWidth = $packDom[0].offsetWidth;
                packHeight = $packDom[0].offsetHeight;
            }

            if (packWidth == 0) packWidth = 328;

            if (imgWidth > packWidth) {
                size = top.Fai.Img.calcSize(imgWidth, imgHeight, packWidth, packHeight, top.Fai.Img.MODE_SCALE_FILL);
                imgWidth = size.width;
                imgHeight = size.height;
            }

            if (top.Fai.isFontIcon(this.imgId)) {
                if (isInit) {
                    imgWidth = moduleWidth;
                    imgHeight = moduleHeight;
                } else {
                    if (moduleWidth > moduleHeight) {
                        imgWidth = moduleHeight;
                        imgHeight = moduleHeight;
                    } else {
                        imgWidth = moduleWidth;
                        imgHeight = moduleWidth;
                    }
                }
            }

            if (!isInit) {
                //等比例转化图片
                imgSize = top.Fai.Img.calcSize(
                    imgWidth,
                    imgHeight,
                    moduleWidth,
                    moduleHeight,
                    top.Fai.Img.MODE_SCALE_FILL
                );
                imgWidth = imgSize.width;
                imgHeight = imgSize.height;
            }

            if (this.picThumbPath == this.defaultImgPath) {
                this.module.otherInfo.isInit = true;
            } else {
                this.module.otherInfo.isInit = false;
            }

            this.imgWidth = imgWidth;
            this.imgHeight = imgHeight;
            this.packWidth = packWidth;
        },

        // 重新更新图片宽、高
        refreshModule() {
            if (this.isInit || !manageMode) return;
            let $module = $('#module' + this.moduleId),
                isFontIcon = this.moduleContent.ifi,
                color = '#666';
            let moduleLeft = $module.position().left,
                fontSize,
                modulePattern;

            let self = this;
            if (!isFontIcon) {
                $module.find('.J_photo').one('load', function () {
                    $module.find('.J_float_img').width(self.imgWidth);
                    $module.find('.J_float_img').height(self.imgHeight);
                    $module.width(self.imgWidth);
                    $module.height(self.imgHeight);
                    $module.find('img').removeClass('fade-in-for-lazyload');
                    floatImgResize($module);
                });
            } else {
                //添加新的图片/修改图片时，要修正图片的宽度。
                $module.find('.J_float_img').width(this.imgWidth);
                $module.find('.J_float_img').height(this.imgHeight);
                $module.width(this.imgWidth);
                $module.height(this.imgHeight);
                color = this.moduleContent.fIcon.fco;
                $module
                    .find('.J_fonticon')
                    .removeClass(function (index, className) {
                        return (className.match(/(^|\s)faisco-icons-\S+/g) || []).join(' ');
                    })
                    .addClass(this.moduleContent.pi);

                //根据模块宽高，调整字体的大小
                fontSize = this.imgWidth > this.imgHeight ? this.imgHeight : this.imgWidth;
                $module
                    .find('.J_fonticon')
                    .css({
                        color,
                        'font-size': fontSize + 'px',
                        'line-height': this.imgHeight + 'px',
                    })
                    .attr('src', this.moduleContent.pi);

                floatImgResize($module); // 绑定图片resize
            }
            if (moduleLeft + this.imgWidth > this.packWidth) {
                $module.css('left', this.packWidth - this.imgWidth + 'px'); // 将模块在自由容器的左位置置为 0
            }

            if (this.isInit) {
                // 修改模块在pack里面的位置，初始化值应该在最左边，不然保存会使用上次的位置
                $module.css('left', 0);
            }

            this.moduleAttr.contentChange = true;
            $module.attr('_autoHeight', 0).attr('_height', this.imgHeight); // 改为不改变自动高
            $module.attr('_autoWidth', 0).attr('_width', this.imgWidth);
            modulePattern = this.moduleAttr.pattern;
            this.modulePattern.w = this.imgWidth;
            this.modulePattern.h = this.imgHeight;
            modulePattern.changed = true;

            Vue.prototype.$designer.styleChanged();
        },

        // 设置模块数据到 dom data 中
        setModuleDataToDom() {
            if (manageMode) {
                let $module = $('#module' + this.moduleId);
                $module.data('contentInfo', this.moduleContent);
                floatImgResize($module);
            }
        },

        resetPicStyle() {
            let $module = $('#module' + this.moduleId);
            let self = this;

            // 说明用户删除了图片，恢复默认
            $module.find('.J_photo').one('load', function () {
                $module.find('.J_float_img').width('initial');
                $module.find('.J_float_img').height('initial');
                self.modulePattern.w = 90; // 90为默认图片宽、高
                self.modulePattern.h = 90;
                $module.width('initial');
                $module.height('initial');

                $module.resizable('destroy');
            });

            this.moduleAttr.contentChange = true;
            $module.attr('_autoHeight', 0).attr('_height', 0);
            $module.attr('_autoWidth', 0).attr('_width', 0);

            let modulePattern = this.moduleAttr.pattern;
            modulePattern.changed = true;

            Vue.prototype.$designer.styleChanged();
        },

        hotAreaListHasLink() {
            return this.hotAreaList.some((item) => {
                return item.jurl != '' && item.jurl != null;
            });
        },

        // 判断是字体id还是图片id
        setPicId(id, path, color) {
            if (manageMode) {
                if (!top.Fai.isFontIcon(id)) {
                    this.moduleContent.ifi = false;
                    this.moduleContent.pi = id;
                } else {
                    this.moduleContent.ifi = true;
                    this.fontIcon.fi = id;
                    this.fontIcon.fc = path;
                    this.fontIcon.fco = color ? color : '#666';
                }
            }
        },

        getAreaPosition(item) {
            let left = Math.round(this.hotAreaWidthScale * item.l);
            let top = Math.round(this.hotAreaHeightScale * item.t);
            let width = Math.round(this.hotAreaWidthScale * item.w);
            let height = Math.round(this.hotAreaHeightScale * item.h);
            let wl = width + left;
            let ht = height + top;
            return left + ',' + top + ',' + wl + ',' + ht;
        },

        getAreaLink(item) {
            if ((item.jType == '103' && manageMode) || (item.jType == 204 && manageMode)) {
                return "javascript:top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问')";
            }
            return item.jurl != '' ? item.jurl : 'javascript:;';
        },

        updatePicShape(id, imgShapeEftData) {
            var $module = jm('#module' + id),
                $floatImgWrap = $module.find('.J_floatImgWrap'),
                $img = $floatImgWrap.find('.J_float_img'),
                $visibleImg = $floatImgWrap.find('.J_photo'), //$floatImgWrap.find(".J_float_img:visible"),$img.eq(0).is(':visible') ? $img.eq(0) : $img.eq(1) -- 字体图标暂不支持img.eq(0)
                borderRadius = imgShapeEftData.borderRadius ? imgShapeEftData.borderRadius : 20,
                imgshapetype = imgShapeEftData.imgShapeType ? imgShapeEftData.imgShapeType : 0,
                imgHeight = $visibleImg.offset().height,
                imgWidth = $visibleImg.offset().width,
                minSize,
                maxSize,
                isWidthLarge = false;
            if (imgshapetype == 1 || imgshapetype == 3) {
                if (imgshapetype == 3) {
                    $floatImgWrap.css('border-radius', '50%');
                }
                if (imgHeight == imgWidth) {
                    return;
                }
                if (imgHeight < imgWidth) {
                    minSize = imgHeight;
                    maxSize = imgWidth;
                    isWidthLarge = true;
                } else {
                    minSize = imgWidth;
                    maxSize = imgHeight;
                }
                $floatImgWrap.css({
                    width: minSize / this.htmlFontSize + 'rem',
                    height: minSize / this.htmlFontSize + 'rem',
                });
                $img.css('margin', '0');
                $floatImgWrap.css('margin', '0');
                if (isWidthLarge) {
                    $img.css('margin-left', -(maxSize - minSize) / (this.htmlFontSize * 2) + 'rem'); // 2*20.5 = 41 20.5：管理态htmlfontsize
                    $floatImgWrap.css('margin-left', (maxSize - minSize) / (this.htmlFontSize * 2) + 'rem');
                } else {
                    $img.css('margin-top', -(maxSize - minSize) / (this.htmlFontSize * 2) + 'rem');
                    $floatImgWrap.css('margin-top', (maxSize - minSize) / (this.htmlFontSize * 2) + 'rem');
                }
            } else if (imgshapetype == 2) {
                $floatImgWrap.css('border-radius', borderRadius / this.htmlFontSize + 'rem');
            }
        },

        removePicShapeStyle() {
            var $module = $('#module' + this.module.id),
                $floatImgWrap = $module.find('.J_floatImgWrap'),
                $img = $floatImgWrap.find('.J_float_img');
            $floatImgWrap.css({
                width: '',
                height: '',
                margin: '',
                'border-radius': '',
            });
            $img.css({
                margin: '',
            });
        },

        removeContainerOpacity() {
            // 去除模块外部的透明度
            let $module = jm('#module' + this.module.id);
            $module.css('opacity', 1);
        },

        picLinkClick(e) {
            if (manageMode) {
                if (this.isJumpWxApp) {
                    Fai.ing('请在微信端打开链接', true);
                    return;
                }
                if (Fai.top._changeStyleNum > 0) {
                    if (
                        (this.isHotArea && this.hotAreaList.length > 0) ||
                        (!this.isHotArea && this.AHref.indexOf('javascript:void(0)') == -1)
                    ) {
                        e.preventDefault();
                        Vue.prototype.$designer.popupStyleChangeBodyWindow('您的网站设计已经更改，是否立即保存？');
                        return;
                    }
                }
            }
            const isAction = this.AHref.startsWith('javascript:');
            if (isAction) {
                eval(this.AHref);
            } else {
                changeHref(this.AHref);
            }
        },

        doubleEdit() {
            if (manageMode) {
                clearTimeout(this.timer);
                logDog(200308, 2);
                //文件上传2.0 图片上传
                const title = this.moduleContent.pi == '' ? '添加图片' : '修改图片';
                const fileUpload2_settings = {
                    title,
                    type: ['jpg', 'jpeg', 'bmp', 'png', 'gif'],
                    entry: 'floatimg',
                    group: 'image',
                    from: 'pic',
                };
                fileUpload2(null, fileUpload2_settings, this.uploadPicCallback);
            }
        },

        // 图片模块特有的幻灯片
        openPictureSlidesV4() {
            if (manageMode) {
                if (this.timer != null) {
                    // 定时器作用，主要用于双击时去除单击事件
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    if (this.module.content.lbp) {
                        this.initPictureSlidesV4();
                    }

                    if (manageMode) {
                        let onlyLoadVisitScript = this.otherInfo.onlyLoadVisitScript || false;
                        // 没有设置链接则要弹出面板
                        if (
                            this.isHotArea &&
                            this.hotAreaList.length == 0 &&
                            !onlyLoadVisitScript &&
                            !window.$store.state.manage.designer.panelOpen
                        ) {
                            logDog(200308, 1);
                            this.$designer.open({
                                panelType: 'pattern float-img',
                                styleId: MODULE_STYLE.FLOAT_IMG,
                                moduleId: this.moduleId,
                            });
                            return;
                        }

                        if (
                            !this.isHotArea &&
                            this.AHref.indexOf('javascript:void(0)') != -1 &&
                            !onlyLoadVisitScript &&
                            !window.$store.state.manage.designer.panelOpen
                        ) {
                            this.$designer.open({
                                panelType: 'pattern float-img',
                                styleId: MODULE_STYLE.FLOAT_IMG,
                                moduleId: this.moduleId,
                            });
                            return;
                        }
                    }
                }, 300);
            } else {
                if (this.module.content.lbp) {
                    this.initPictureSlidesV4();
                }
            }
        },

        initPictureSlidesV4() {
            // 初始化图片不进行该幻灯片操作
            let isOpenLink = false;
            if (this.isHotArea) {
                isOpenLink = false;
            } else {
                isOpenLink = this.AHref != 'javascript:;' && this.AHref.indexOf('javascript:void(0)') == -1;
            }
            // eslint-disable-next-line no-constant-condition
            if (isOpenLink) return;
            this.showMobiViewer = true;
        },

        updateHotAreaSize() {
            let $img = jm('#module' + this.module.id + ' img');
            if ($img.length) {
                this.hotAreaRealWidth = $img[0].offsetWidth;
                this.hotAreaRealHeight = $img[0].offsetHeight;
            }
        },
        areaClick(e, item) {
            if (VITE_APP_MODE !== 'visitor') {
                e.preventDefault();
                changeHref(this.getAreaLink(item));
            }
        },
    },
};
</script>
<style>
.formStyle53.form {
    /*width:4.39024rem;
	height:4.39024rem;*/
    padding: 0;
    border: 0;
    border-radius: 0;
    background: none;
    overflow: visible;
    box-shadow: none;
}
.formStyle53.form .formMiddle {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    margin: 0;
    background: none;
    overflow: initial;
}
.formStyle53.form .formMiddle .middleCenter {
    height: 100%;
}
.formStyle53.form .formMiddle .formMiddleContent {
    height: 100%;
}
.formStyle53.form .formMiddle .fk-floatImgContainer {
    height: 100%;
}

.formStyle53 .formMiddle .formMiddleContent {
    overflow: visible;
    overflow-y: visible;
    background: none;
} /*//兼容ie -ms-overflow-y  */
.formStyle53 img {
    max-width: none;
    max-height: none;
}
.formStyle53 .floatImgWrap {
    overflow: hidden;
    height: 100%;
}
.formStyle53 .float_img_default {
    /*width: 3.8814rem;
	height: 3.8814rem;*/
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: middle;
}
.formStyle53 .f-fonticon {
    text-align: center;
}
</style>
